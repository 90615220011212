import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 8-8-8-8\\@70% 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Rankel”`}</strong></p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`6-Deadlifts (225/155)`}</p>
    <p>{`7-Burpee Pullups`}</p>
    <p>{`10-KBs’s (70/53)`}</p>
    <p>{`200M Run`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Open gym at Louisville East today, and for the rest of the fall and
winter, from 8:30am on excluding class times.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 4 is filled with teams ready to rumble for 1st place this
Saturday, November 5th! Come out and watch the action at The Ville
starting at 9:00am. `}<a parentName="em" {...{
            "href": "https://truegritcomp.wordpress.com"
          }}>{`https://truegritcomp.wordpress.com`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no 5:30pm class or open gym after 5:30 this Friday at
The Ville so that we can set up for True Grit. If anyone is available to
help set up please show up at 5:30.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We are collecting blankets, scarves and gloves at Louisville East
and The Ville for the homeless this winter. If you’d like to donate
please do so by November 12th. There are bins at each location for your
donations. Thanks!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts Sunday, November 6th. For more
info contact Eric at fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      